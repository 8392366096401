<template>
  <div>
    <el-table
    :show-header="true"
    v-loading='loading'
    :data="blocks"
    @cell-click="clickCell"
    style="width: 100%">
      <el-table-column label="Блок код">
        <template slot-scope="scope">
          {{scope.row.super_block_code}}
        </template>
      </el-table-column>
      <el-table-column label="Гарчиг">
          <template slot-scope="scope">
          <el-row :gutter="10" align="left" type="flex">
            <div :style="`backgroundColor: ${scope.row.title_color}; width:20px; height:20px; border-radius: 10px; margin-right: 5px; border-width: 1px; border-color: gray; border-style: solid;`"></div>
            {{scope.row.name_mon}}
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="Байрлах хуудас">
         <template slot-scope="scope">
           {{scope.row.page}}
        </template>
      </el-table-column>
      <el-table-column label="Төлөв">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'active'" type="success" effect="dark">Active</el-tag>
          <el-tag v-else-if="scope.row.status === 'expired'" type="info" effect="dark">Expired</el-tag>
          <el-tag v-else type="warning" effect="dark">{{scope.row.status}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Дэвсгэр өнгө, Эрэмбэ">
        <template slot-scope="scope">
          <el-row :gutter="10" type="flex">
            <el-col :span="12">
              <div v-if="scope.row.background_type === 'color'" :style="`backgroundColor: ${scope.row.background}; width:50px; height:20px; border-width: 1px; border-color: gray; border-style: solid;`"></div>
              <div v-else>
                <el-image :src="`${scope.row.background}`" style="width: 50px; height: 50px;">
                </el-image>
              </div>
            </el-col>
            <el-col :span="12">
              {{scope.row.sort}}
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="Эхлэх огноо">
        <template slot-scope="scope">
          <div>{{formatDate(scope.row.start_date)}}</div>
        </template>
        </el-table-column>
        <el-table-column label="Дуусах огноо">
         <template slot-scope="scope">
          <div>{{formatDate(scope.row.end_date)}}</div>
         </template>
        </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import helper from '../../../../helpers/helper'
export default {
  name: 'BlockData',
  props: [
    'search',
    'blocks',
    'loading',
    'totalCount',
    'pageSize',
    'currentPage',
    'curentPageHandler',
    'sizeChangeHandler',
    'tab'
  ],

  methods: {
    formatDate (val) {
      return helper.formatDate(val, 'YYYY/MM/DD')
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'blockEdit',
          params: {
            id: row.super_block_id
          },
          query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.tab }
        }
      )
    }
  }

}
</script>
